import { Commit } from "vuex"

interface State {
	id: string | null
}

const getDefaultState = (): State => {
	return {
		id: null,
	}
}

export default {
	state: getDefaultState(),
	getters: {
		sessionId(state: State): string | null {
			return state.id
		},
	},
	mutations: {
		setId(state: State, id: string) {
			state.id = id
		},
		unsetId(state: State) {
			state.id = null
		},
		resetState(state: State) {
			Object.assign(state, getDefaultState())
		},
	},
	actions: {
		setId({ commit }: { commit: Commit }, id: string) {
			commit("setId", id)
		},
		unsetId({ commit }: { commit: Commit }) {
			commit("unsetId")
		},
		resetSession({ commit }: { commit: Commit }) {
			commit("resetState")
		},
	},
}
