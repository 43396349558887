
import { defineComponent, inject } from "vue"
import { IToast } from "@/interfaces"
import i18n from "@/plugins/i18n"

export default defineComponent({
	name: "VToast",
	data() {
		return {
			show: false,
			message: "",
			timeout: 3000,
		}
	},
	setup() {
		const eventBus = inject("eventBus")
		const { t } = i18n.global
		return { eventBus, t }
	},
	created() {
		this.bindEvents()
	},
	methods: {
		bindEvents() {
			this.eventBus.on("toast", (data: IToast) => this.handleToast(data))
		},
		handleToast(data: IToast) {
			this.message = data.message || this.t("ui.missingMessage")
			this.timeout = data.timeout || this.timeout
			this.show = true
		},
	},
})
