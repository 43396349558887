import { Commit } from "vuex"
const getDefaultState = () => {
	return {
		isLoading: false,
	}
}

export default {
	namespaced: true,
	state: getDefaultState(),
	getters: {
		isLoading(state: { isLoading: boolean }) {
			return state.isLoading
		},
	},
	mutations: {
		setLoading(state: { isLoading: boolean }, payload: boolean) {
			state.isLoading = payload
		},
	},
	actions: {
		startLoading({ commit }: { commit: Commit }) {
			commit("setLoading", true)
		},
		stopLoading({ commit }: { commit: Commit }) {
			commit("setLoading", false)
		},
	},
}
