export function utils() {
	/**
	 * Formats a timestamp to a human-readable date.
	 * @param {number | string} timestamp - The timestamp to format.
	 * @returns {string} - The formatted date string.
	 */
	function formatDate(timestamp: number | string): string {
		return timestamp ? new Date(Number(timestamp)).toDateString() : "Never"
	}

	/**
	 * On occasions we have to use double requestAnimationFrame whenever
	 * we find ourselves toggling classes or similar
	 * that requires RAF to fire after an action is performed.
	 * @param {() => void} callback - The callback function to execute.
	 * @returns {void}
	 */
	function doubleRaf(callback: () => void): void {
		requestAnimationFrame(() => {
			requestAnimationFrame(callback)
		})
	}

	/**
	 * Toggles the tooltip overlay (crude bug fix for Vuetify).
	 * @param {Event} e - The event object.
	 * @param {boolean} remove - Whether to remove the tooltip.
	 * @returns {void}
	 */
	function handleTooltip(e: Event, remove: boolean): void {
		const parentElement = e.currentTarget as HTMLElement
		const overlays =
			parentElement?.parentElement?.querySelectorAll(".v-tooltip")

		overlays?.forEach((overlay) => {
			const content = overlay.querySelector(
				".v-overlay__content"
			) as HTMLElement
			if (remove) {
				overlay.classList.remove("v-overlay--active")
				if (content) {
					content.style.display = "none"
					content.focus()
				}
			} else {
				overlay.classList.add("v-overlay--active")
				if (content) {
					content.style.display = "block"
					content.focus()
				}
			}
		})
	}

	return { formatDate, doubleRaf, handleTooltip }
}
