import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex d-md-none mr-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar_items = _resolveComponent("v-toolbar-items")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_app_bar, {
      elevation: "1",
      class: "px-4 bg-accent-2"
    }, {
      default: _withCtx(() => [
        (_ctx.isUserWithRole)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
              _createVNode(_component_v_app_bar_nav_icon, {
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.drawer = !_ctx.drawer), ["stop"]))
              })
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_v_sheet, {
          class: "logo",
          color: "transparent"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_router_link, { to: "/" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_img, {
                  src: require('@/assets/images/logo.svg'),
                  alt: "logo",
                  width: "75",
                  height: "32"
                }, null, 8, ["src"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.isAdmin)
          ? (_openBlock(), _createBlock(_component_v_toolbar_items, {
              key: 1,
              class: "d-none d-md-flex"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.adminItems, (item) => {
                  return (_openBlock(), _createBlock(_component_router_link, {
                    key: item.title,
                    to: item.to,
                    custom: ""
                  }, {
                    default: _withCtx(({ navigate }) => [
                      _createVNode(_component_v_btn, {
                        onClick: navigate,
                        role: "link",
                        "prepend-icon": item.icon
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.title), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick", "prepend-icon"])
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.isFacilitator)
          ? (_openBlock(), _createBlock(_component_v_toolbar_items, {
              key: 2,
              class: "d-none d-md-flex"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.facilitatorItems, (item) => {
                  return (_openBlock(), _createBlock(_component_router_link, {
                    key: item.title,
                    to: item.to,
                    custom: ""
                  }, {
                    default: _withCtx(({ navigate }) => [
                      _createVNode(_component_v_btn, {
                        onClick: navigate,
                        role: "link",
                        "prepend-icon": item.icon
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.title), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick", "prepend-icon"])
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_v_spacer),
        (_ctx.user && _ctx.user.displayName)
          ? (_openBlock(), _createBlock(_component_v_sheet, {
              key: 3,
              class: "text-overline mr-3",
              color: "transparent"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.user.displayName), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.user)
          ? (_openBlock(), _createBlock(_component_v_menu, {
              key: 4,
              "offset-y": ""
            }, {
              activator: _withCtx(({ props: menu }) => [
                _createVNode(_component_v_btn, _mergeProps({
                  variant: "flat",
                  size: "x-small",
                  color: _ctx.user.color || 'white'
                }, { ...menu }, { icon: "mdi-account-circle" }), null, 16, ["color"])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_list, null, {
                  default: _withCtx(() => [
                    (!_ctx.isAuthenticated)
                      ? (_openBlock(), _createBlock(_component_v_list_item, { key: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_btn, {
                                  plain: "",
                                  to: "/login",
                                  "prepend-icon": "mdi-login"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("ui.login")), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isAuthenticated)
                      ? (_openBlock(), _createBlock(_component_v_list_item, { key: 1 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_btn, {
                                  variant: "flat",
                                  to: "/logout",
                                  "prepend-icon": "mdi-logout"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("ui.logout")), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_v_navigation_drawer, {
      modelValue: _ctx.drawer,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.drawer) = $event)),
      fixed: "",
      temporary: ""
    }, {
      default: _withCtx(() => [
        (_ctx.isAdmin)
          ? (_openBlock(), _createBlock(_component_v_list, {
              key: 0,
              dense: ""
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.adminItems, (item) => {
                  return (_openBlock(), _createBlock(_component_v_list_item, {
                    "prepend-icon": item.icon,
                    key: item.title,
                    to: item.to
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.title), 1)
                    ]),
                    _: 2
                  }, 1032, ["prepend-icon", "to"]))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.isFacilitator)
          ? (_openBlock(), _createBlock(_component_v_list, {
              key: 1,
              dense: ""
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.facilitatorItems, (item) => {
                  return (_openBlock(), _createBlock(_component_v_list_item, {
                    "prepend-icon": item.icon,
                    key: item.title,
                    to: item.to
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.title), 1)
                    ]),
                    _: 2
                  }, 1032, ["prepend-icon", "to"]))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}