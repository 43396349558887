
import { defineComponent } from "vue"
import { mapState } from "vuex"

export default defineComponent({
	name: "VLoading",
	computed: {
		...mapState("loading", ["isLoading"]),
	},
})
