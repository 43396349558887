import { Commit } from "vuex"
import { errorHandler } from "@/composables"
import firebase, { database } from "@/firebase"
import { ref, update } from "firebase/database"
import {
	getAuth,
	signInAnonymously,
	signInWithEmailAndPassword,
	signInWithEmailLink,
	signOut,
	UserCredential,
} from "firebase/auth"
import { IClaims, IError, IUserAuth } from "@/interfaces"

const { onError } = errorHandler()
const getDefaultState = () => {
	return {
		user: null,
	}
}

export default {
	state: getDefaultState(),
	getters: {
		isAuthenticated(state: { user: IUserAuth }) {
			return !!state.user
		},
		isAdmin(state: { user: IUserAuth }) {
			return !!state.user && state.user.role === "admin"
		},
		isFacilitator(state: { user: IUserAuth }) {
			return !!state.user && state.user.role === "facilitator"
		},
		user(state: { user: IUserAuth }) {
			return state.user
		},
	},
	mutations: {
		signIn(state: { user: IUserAuth }, user: IUserAuth) {
			state.user = { ...user }
		},
		signInAnonymously(state: { user: IUserAuth }, user: IUserAuth) {
			state.user = { ...user }
		},
		signOut(state: { user: IUserAuth | null }) {
			state.user = null
		},
		userRole(state: { user: IUserAuth }, user: IUserAuth) {
			state.user = { ...user }
		},
		updateUser(state: { user: IUserAuth }, user: IUserAuth) {
			state.user = { ...state.user, ...user }
		},
	},
	actions: {
		async signInWithEmailLink(
			{ commit }: { commit: Commit },
			data: { email: string; url: string }
		) {
			const auth = await getAuth()
			const userCredential: UserCredential = await signInWithEmailLink(
				auth,
				data.email,
				data.url
			)
			await update(ref(database, `users/${userCredential.user.uid}`), {
				metadata: { lastLoginAt: firebase.database.ServerValue.TIMESTAMP },
			})
			commit("signIn", userCredential.user)
		},
		async signInWithEmailAndPassword(
			{ commit }: { commit: Commit },
			{ email, password }: { email: string; password: string }
		) {
			try {
				const auth = await getAuth()
				const userCredential: UserCredential = await signInWithEmailAndPassword(
					auth,
					email,
					password
				)
				await update(ref(database, `users/${userCredential.user.uid}`), {
					metadata: { lastLoginAt: firebase.database.ServerValue.TIMESTAMP },
				})
				commit("signIn", userCredential.user)
			} catch (error) {
				onError(error as IError)
			}
		},
		async signInAnonymously({ commit }: { commit: Commit }) {
			try {
				const auth = await getAuth()
				const { user } = await signInAnonymously(auth)
				commit("signInAnonymously", user)
				return user
			} catch (error) {
				onError(error as IError)
			}
		},
		async signOut({ commit }: { commit: Commit }) {
			try {
				const auth = await getAuth()
				await signOut(auth)
				commit("signOut")
			} catch (error) {
				onError(error as IError)
			}
		},
		// Getters are not type checked https://github.com/vuejs/vuex/issues/1756
		async userRole(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			{ commit, getters }: { commit: Commit; getters: any },
			claims: IClaims
		) {
			const roleAdmin = claims.admin && "admin"
			const roleFacilitator = claims.facilitator && "facilitator"
			const role = roleAdmin || roleFacilitator || null
			if (getters.user && role) {
				const user = { ...getters.user, role }
				await update(ref(database, `users/${user.uid}`), {
					email: user.email,
					role: user.role,
				})
				commit("userRole", user)
			}
		},
		updateUser({ commit }: { commit: Commit }, user: IUserAuth) {
			commit("updateUser", user)
		},
	},
}
