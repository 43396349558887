import { createStore } from "vuex"
import createPersistedState from "vuex-persistedstate"
import auth from "./auth"
import session from "./session"
import loading from "./loading"

const store = createStore({
	plugins: [createPersistedState()],
	state: {},
	getters: {},
	mutations: {},
	actions: {},
	modules: {
		auth,
		session,
		loading,
	},
})

export default store
