import { NavigationGuardNext, RouteLocationNormalized } from "vue-router"
import { errorHandler } from "@/composables"
import store from "@/store"
import {
	child,
	equalTo,
	get,
	orderByChild,
	query,
	ref,
	set,
} from "firebase/database"
import { database } from "@/firebase"
import { IError } from "@/interfaces/IError"
import { IUserAuth } from "@/interfaces"
import i18n from "@/plugins/i18n"

const { t } = i18n.global
const { onError } = errorHandler()
const experiencesRef = ref(database, "experiences")
const sessionsRef = ref(database, "sessions")

export default [
	{
		path: "/:catchAll(.*)",
		redirect: "/not-found",
	},
	{
		path: "/",
		name: "landing",
		redirect: "/login",
	},
	{
		path: "/not-found",
		name: "not-found",
		meta: {
			title: "Not Found",
		},
		component: () => import("@/views/NotFound.vue"),
	},
	{
		path: "/not-available",
		name: "not-available",
		meta: {
			title: "Not Available",
		},
		component: () => import("@/views/NotAvailable.vue"),
	},
	{
		path: "/forbidden",
		name: "forbidden",
		meta: {
			title: "Forbidden",
		},
		component: () => import("@/views/Forbidden.vue"),
	},
	{
		path: "/login",
		name: "login",
		meta: {
			title: "Login",
		},
		component: () => import("../views/Login.vue"),
	},
	{
		path: "/logout",
		name: "logout",
		meta: {
			title: "Logout",
		},
		component: () => import("../views/Logout.vue"),
	},
	{
		path: "/profile",
		name: "profile",
		meta: {
			title: "Profile",
			auth: true,
		},
		component: () => import("../views/Profile.vue"),
	},
	{
		path: "/password",
		name: "password",
		meta: {
			title: "Password",
		},
		component: () => import("../views/Password.vue"),
	},
	{
		path: "/config",
		name: "config-list",
		meta: {
			title: t("routes.experiences"),
			auth: true,
		},
		component: () => import("../views/ConfigList.vue"),
	},
	{
		path: "/facilitator",
		name: "facilitator-list",
		meta: {
			title: t("routes.hosts"),
			auth: true,
		},
		component: () => import("../views/FacilitatorList.vue"),
	},
	{
		path: "/dashboard",
		name: "dashboard-list",
		meta: {
			title: t("routes.sessions"),
			auth: true,
		},
		component: () => import("@/views/DashboardList.vue"),
	},
	{
		path: "/dashboards/:gid",
		name: "dashboard-items",
		meta: {
			title: "Dashboard Items",
			auth: true,
		},
		component: () => import("@/views/DashboardItems.vue"),
		beforeEnter: async (
			to: RouteLocationNormalized,
			from: RouteLocationNormalized,
			next: NavigationGuardNext
		) => {
			try {
				const queryConstraints = [
					orderByChild("gid"),
					equalTo(to.params.gid as string),
				]
				const experienceSnapshot = await get(
					query(experiencesRef, ...queryConstraints)
				)
				if (experienceSnapshot.exists()) {
					next()
				} else {
					next({ name: "experience" })
				}
			} catch (error) {
				onError(error as IError)
			}
		},
	},
	{
		path: "/dashboard/:expid",
		name: "dashboard-item",
		meta: {
			title: "Dashboard Item",
			auth: true,
		},
		props: true,
		component: () => import("@/views/DashboardItem.vue"),
		beforeEnter: async (
			to: RouteLocationNormalized,
			from: RouteLocationNormalized,
			next: NavigationGuardNext
		) => {
			try {
				const snapshot = await get(
					child(experiencesRef, to.params.expid as string)
				)
				if (snapshot.exists()) {
					next()
				} else {
					next({ name: "not-found" })
				}
			} catch (error) {
				onError(error as IError)
			}
		},
	},
	{
		path: "/join",
		name: "join",
		meta: {
			title: "Join",
		},
		component: () => import("../views/Join.vue"),
		beforeEnter: async (
			to: RouteLocationNormalized,
			from: RouteLocationNormalized,
			next: NavigationGuardNext
		) => {
			const sessionId = store.getters.sessionId
			if (sessionId) {
				try {
					const snapshot = await get(child(sessionsRef, sessionId as string))
					if (snapshot.exists()) {
						next()
					} else {
						next({ name: "not-found" })
					}
				} catch (error) {
					onError(error as IError)
				}
			} else {
				next({ name: "not-found" })
			}
		},
	},
	{
		path: "/experience/:expid",
		name: "experience",
		meta: {
			title: "Experience",
		},
		props: true,
		component: () => import("../views/Experience.vue"),
		beforeEnter: async (
			to: RouteLocationNormalized,
			from: RouteLocationNormalized,
			next: NavigationGuardNext
		) => {
			try {
				const { isAuthenticated, user } = await store.getters
				const sessionId = store.getters.sessionId
				const snapshot = await get(
					child(experiencesRef, to.params.expid as string)
				)
				if (!snapshot.exists()) {
					next({ name: "not-found" })
				} else {
					if (!isAuthenticated) {
						next({ name: "join" })
					} else {
						if (!user.role) {
							const users = snapshot.val().users
							const userExists =
								users && Object.keys(users).filter((uid) => uid === user.uid)[0]
							if (userExists) {
								next()
							} else {
								const snapshot = await get(
									child(sessionsRef, sessionId as string)
								)
								if (snapshot.exists()) {
									const users = snapshot.val().users
									const userExists: string =
										users &&
										Object.keys(users).filter((uid) => uid === user.uid)[0]
									const userAuth: IUserAuth = users[userExists]
									await set(
										child(
											experiencesRef,
											`${to.params.expid}/users/${user.uid}`
										),
										userAuth
									)
									next()
								}
							}
						} else {
							next()
						}
					}
				}
			} catch (error) {
				onError(error as IError)
			}
		},
	},
	{
		path: "/session/:gid",
		name: "session",
		meta: {
			title: "Session",
		},
		component: () => import("../views/Session.vue"),
		beforeEnter: async (
			to: RouteLocationNormalized,
			from: RouteLocationNormalized,
			next: NavigationGuardNext
		) => {
			try {
				store.dispatch("setId", to.params.gid)
				const snapshot = await get(child(sessionsRef, to.params.gid as string))
				if (!snapshot.exists() || snapshot.val().closed) {
					next({ name: "not-available" })
				} else {
					const { isAuthenticated, user } = store.getters
					if (!isAuthenticated) {
						next({ name: "join" })
					} else {
						if (!user.role) {
							const users = snapshot.val().users
							const userExists =
								users && Object.keys(users).filter((uid) => uid === user.uid)[0]
							if (userExists) {
								next()
							} else {
								next({ name: "join" })
							}
						} else {
							next()
						}
					}
				}
			} catch (error) {
				onError(error as IError)
			}
		},
	},
]
