import Swal from "sweetalert2"
import { IError } from "@/interfaces/IError"

export function errorHandler() {
	function onError(error: IError) {
		console.error(error)
		const { code, response, message } = error

		if (response) {
			const { status } = response
			if (status >= 400 && status < 500) {
				// Client error responses 4xx.
				handleClientError(status)
				return
			} else if (status >= 500) {
				// Server error responses 5xx.
				displayErrorMessage("A server error occurred. Please try again later.")
				return
			}
		}

		if (code) {
			handleAuthError(code, message)
			return
		}

		displayErrorMessage(message || "An unknown error occurred.")
	}

	function handleClientError(status: number) {
		switch (status) {
			case 400:
				displayErrorMessage("Bad request. Please check your input.")
				break
			case 401:
				displayErrorMessage("Unauthorized. Please log in.")
				break
			case 403:
				displayErrorMessage("Forbidden. You do not have permission.")
				break
			case 404:
				displayErrorMessage(
					"Not found. The requested resource could not be found."
				)
				break
			default:
				displayErrorMessage("A client error occurred. Please try again.")
		}
	}

	function handleAuthError(code: string, message?: string) {
		switch (code) {
			case "auth/wrong-password":
				displayErrorMessage("The password is incorrect")
				break
			case "auth/user-not-found":
				displayErrorMessage("The email address has not been found")
				break
			default:
				displayErrorMessage(message || "An authentication error occurred.")
		}
	}

	function displayErrorMessage(message: string) {
		Swal.fire({
			title: "Error!",
			text: message,
			icon: "error",
			showCancelButton: false,
			showConfirmButton: true,
		})
	}

	return { onError }
}
