
import { defineComponent } from "vue"
import { mapGetters } from "vuex"
import i18n from "@/plugins/i18n"

export default defineComponent({
	name: "VNavBar",
	setup() {
		const { t } = i18n.global
		return { t }
	},
	data() {
		return {
			drawer: false,
			adminItems: [
				{
					icon: "mdi-cog",
					to: "/config",
					title: this.t("routes.experiences"),
				},
				{
					icon: "mdi-account-supervisor",
					to: "/facilitator",
					title: this.t("routes.hosts"),
				},
				{
					icon: "mdi-view-dashboard",
					to: "/dashboard",
					title: this.t("routes.sessions"),
				},
			],
			facilitatorItems: [
				{
					icon: "mdi-view-dashboard",
					to: "/dashboard",
					title: this.t("routes.sessions"),
				},
			],
		}
	},
	computed: {
		...mapGetters(["isAuthenticated", "isAdmin", "isFacilitator", "user"]),
		isUserWithRole() {
			return this.isAdmin || this.isFacilitator
		},
	},
})
