import "@mdi/font/css/materialdesignicons.css"
import "vuetify/styles"
import "@/assets/scss/variables.scss"
import "@/assets/scss/settings.scss"

import { createVuetify, ThemeDefinition } from "vuetify"

const defaults = {
	global: {
		"persistent-placeholder": true,
	},
	VTextField: {
		variant: "solo",
	},
	VSelect: {
		variant: "solo",
	},
	VFileInput: {
		variant: "solo",
	},
}

const customLightTheme: ThemeDefinition = {
	dark: false,
	colors: {
		heading: "#3e3e3e",
		text: "#383838",
		"text-2": "6d6d6d",
		"text-dark": "#4b4b4b",
		disclaimer: "#061d37",
		"text-accent": "#0064c1",
		accent: "#0EBDBA",
		"accent-2": "003fe2",
		"accent-3": "#684eec",
		"main-bg": "#fff",
		"accent-bg": "#f0f9ff",
		"accent-bg-2": "#dbf0fc",
		primary: "#0000c9",
		"link-2": "#1753ec",
		"btn-hover": "#bfbff1",
		disabled: "#6f6f6f",
		"disabled-2": "#a1aab1",
		"disabled-3": "#eaeaea",
		"disabled-4": "#999999",
		danger: "#e11900",
		"danger-2": "#e85340",
		"danger-3": "#ce484f",
		"danger-4": "#d83906",
		"open-dropdown": "#9747ff",
		"table-head": "#00004e",
		"table-head-active": "#4040d7",
		"table-row": "#f2f2f2",
		"table-row-hover": "#f2f2fc",
		"table-border": "#c1c7cd",
		success: "#018736",
		"success-2": "#05a357",
		"success-light": "#e6f6ee",
		alert: "#292929",
		divider: "#7f7f7f",
		"bg-prefilled": "#f1f1f1",
		warning: "#ffc043",
		black: "#000",
		"event-title": "#1e1e1e",
	},
}

export default createVuetify({
	defaults,
	theme: {
		defaultTheme: "customLightTheme",
		variations: {
			colors: [
				...(customLightTheme.colors
					? Object.keys(customLightTheme.colors)
					: []),
			],
			lighten: 5,
			darken: 5,
		},
		themes: {
			customLightTheme,
		},
	},
})
