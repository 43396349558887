import {
	createRouter,
	NavigationGuardNext,
	RouteLocationNormalized,
	createWebHistory,
} from "vue-router"
import routes from "@/router/routes"
import store from "@/store"
import i18n from "@/plugins/i18n"

const { t } = i18n.global
const router = createRouter({
	history: createWebHistory(),
	routes,
})

const navigationGuards = async (
	to: RouteLocationNormalized,
	from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	store.commit("loading/setLoading", true)
	const { isAuthenticated, isAdmin, isFacilitator } = store.getters

	if (isAuthenticated) {
		if (to.name === "login") {
			next({ name: "profile" })
		} else if (to.name === "logout") {
			await store.dispatch("signOut")
			next({ name: "login" })
		} else {
			if (isAdmin) {
				next()
			} else if (isFacilitator) {
				if (!/^\/config|\/facilitator/.test(to.path)) {
					next()
				} else {
					next({ name: "forbidden" })
				}
			} else {
				if (!/^\/config|\/facilitator|\/dashboard/.test(to.path)) {
					next()
				} else {
					next({ name: "forbidden" })
				}
			}
		}
	} else {
		if (to.matched.some((record) => record.meta.auth)) {
			next({ name: "login" })
		} else {
			next()
		}
	}

	document.title = `${to.meta.title} | ${t("global.name")}` as string
}

router.beforeEach(navigationGuards)
router.afterEach(() => store.commit("loading/setLoading", false))

export default router
