import firebase from "firebase/compat/app"
import "firebase/compat/database"
import { getDatabase, Database } from "firebase/database"
import { getStorage, FirebaseStorage } from "firebase/storage"

const config = {
	apiKey: "AIzaSyChh3AQ4HYuDSRPmEO-fhppwljAeeg_j7A",
	authDomain: "omnichannel-3b80d1cf.firebaseapp.com",
	databaseURL: "https://omnichannel-3b80d1cf.firebaseio.com",
	projectId: "omnichannel-3b80d1cf",
	storageBucket: "gcf-sources-170426517486-us-central1",
	messagingSenderId: "170426517486",
	appId: "1:170426517486:web:25482bfda1bb0f6cd130ff",
}

export const app: firebase.app.App = firebase.initializeApp(config)
export const storage: FirebaseStorage = getStorage(app)
export const database: Database = getDatabase(app)
export default firebase
