
import { defineComponent } from "vue"
import VNavBar from "@/components/ui/NavBar.vue"
import VToast from "@/components/ui/Toast.vue"
import VLoading from "@/components/ui/Loading.vue"
import { useHead } from "@unhead/vue"
import i18n from "@/plugins/i18n"

export default defineComponent({
	name: "app",
	components: {
		VNavBar,
		VToast,
		VLoading,
	},
	setup() {
		useHead({
			htmlAttrs: {
				lang: i18n.global.locale,
			},
		})
	},
})
