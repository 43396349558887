import { createApp } from "vue"
import App from "@/App.vue"
import i18n from "@/plugins/i18n"
import vuetify from "@/plugins/vuetify"
import router from "@/router"
import store from "@/store"
import VueSweetalert2 from "vue-sweetalert2"
import { errorHandler } from "@/composables"
import { IError } from "@/interfaces/IError"
import { VueFire, VueFireDatabaseOptionsAPI, VueFireOptions } from "vuefire"
import { getAuth } from "firebase/auth"
import mitt from "mitt"
import { createHead } from "@unhead/vue"

import "sweetalert2/dist/sweetalert2.min.css"
import "@/assets/scss/overrides.scss"

const { onError } = errorHandler()
const eventBus = mitt()
const head = createHead()

async function checkFirebaseConfig() {
	try {
		return await getAuth()
	} catch (error) {
		onError(error as IError)
	}
}

checkFirebaseConfig().then(() => {
	const app = createApp(App)
	app.config.errorHandler = (error) => onError(error as IError)
	app.provide("eventBus", eventBus)
  app.use(head)
	app.use(i18n)
	app.use(vuetify)
	app.use(router)
	app.use(store)
	app.use(VueFire, { modules: [VueFireDatabaseOptionsAPI()] } as VueFireOptions)
	app.use(VueSweetalert2)
	app.mount("#app")
})
